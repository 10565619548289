<template>
  <div>
    <Table v-if="$route.name === 'projectDataSets'" />
    <Editor v-else-if="$route.name === 'projectDataSetsEditor'" />
  </div>
</template>

<script>
import Editor from "@/components/Projects/DataSets/Editor/Index";
import Table from "@/components/Projects/DataSets/Table";

export default {
  components: {
    Editor,
    Table
  }
};
</script>
