<template>
  <div class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("dataSets.dataSets") }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary btn-hover" @click="showAddDataSet">
          <i class="fal fa-plus"></i>
          {{ $t("dataSets.createDataSet") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadDataSets">
        <b-table
          responsive
          :items="dataSets"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(updated_at)="data">
            {{ formatDateAssigned(data.item.updated_at) }}
          </template>
          <template #cell(actions)="data">
            <button
              v-if="!data.item.is_locked"
              v-b-tooltip.top.noninteractive="$t('dataSets.openEditor')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="editDataSet(data.item.id)"
            >
              <i class="fal fa-paint-brush" />
            </button>
            <button
              v-b-tooltip.top.noninteractive="$t('dataSets.deleteTitle')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteDataSet(data.item)"
            >
              <i class="fal fa-trash" />
            </button>
          </template>
        </b-table>
      </TableWrapper>
    </div>

    <b-modal
      ref="createDataPipeline"
      :title="$t('dataSets.createDataSet')"
      :cancel-title="$t('general.cancel')"
      :ok-title="$t('dataSets.create')"
      centered
      @ok="createDataSet"
    >
      <input
        ref="createDataPipelineName"
        v-model="newDataPipeline.name"
        class="form-control"
        :placeholder="$t('dataSets.name')"
        @keydown="onNewDataPipelineNameKeydown"
      />
    </b-modal>
  </div>
</template>

<script>
import DataPipelines from "@/components/Projects/DataSets/dataPipelines";
import StageFactory from "@/components/Projects/DataSets/stageFactory";

import { formatDate } from "@/components/Tools/modifiers";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_DATA_PIPELINE_CREATED } from "@/core/services/store/dataSets.module";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  components: { TableWrapper },
  data() {
    return {
      isBusy: false,
      dataSets: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          thStyle: { "min-width": "70px", "max-width": "70px", width: "70px" },
          sortable: true
        },
        {
          key: "name",
          label: this.$t("table.name"),
          sortable: true
        },
        {
          key: "updated_at",
          label: this.$t("table.updatedAt"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          thStyle: {
            "min-width": "100px",
            "max-width": "100px",
            width: "100px"
          },
          sortable: false
        }
      ],
      meta: {},
      newDataPipeline: {
        name: ""
      }
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.loadDataSets();
    this.setBreadcrumb();

    this.$root.$on("bv::modal::shown", this.onCreateDataPipelineModalShown);
  },
  destroyed() {
    this.$root.$off("bv::modal::shown", this.onCreateDataPipelineModalShown);
  },
  methods: {
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("dataSets.dataSets"),
          route: ""
        },
        {
          title: this.$t("general.overview"),
          route: "/project/dataStore"
        }
      ]);
    },
    loadDataSets() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      DataPipelines.getAll(params, cancelToken)
        .then(response => {
          this.dataSets = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    formatDateAssigned(value) {
      if (value.length) {
        return formatDate(value);
      }
      return "";
    },
    showAddDataSet() {
      this.newDataPipeline = {
        name: "",
        active: 1,
        pipelineStages: [StageFactory.makeQueryStage(0)]
      };
      this.$refs["createDataPipeline"].show();
    },
    onNewDataPipelineNameKeydown(event) {
      if (event.key !== "Enter") {
        return;
      }
      this.createDataSet();
    },
    createDataSet() {
      if (this.newDataPipeline.name.trim().length === 0) {
        return;
      }
      this.$store.dispatch(SET_DATA_PIPELINE_CREATED, this.newDataPipeline);
      this.$router.push({ name: "projectDataSetsEditor" });
    },
    onCreateDataPipelineModalShown() {
      this.$refs["createDataPipelineName"].focus();
    },
    editDataSet(id) {
      this.$router.push({ name: "projectDataSetsEditor", params: { id: id } });
    },
    deleteDataSet(dataSet) {
      let id = dataSet.id;
      let name = dataSet.name;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("dataSets.deleteTitle"),
          text: this.$t("dataSets.deleteText", { name: name }),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel"),
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "#F64E60"
        })
        .then(result => {
          if (!result.isConfirmed) return;
          this.deleteDataSetAction(id);
        });
    },
    deleteDataSetAction(id) {
      this.isBusy = true;
      DataPipelines.delete(id)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("dataSets.deletedSuccess")
          });
          this.loadDataSets();
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>

<style scoped></style>
